import{ ModuleWithProviders} from '@angular/core';
import{Routes, RouterModule} from '@angular/router';


import {HomeComponent} from './home/home.component';
import {ProductosComponent} from './productos/productos.component';
import {ContactoComponent} from './contacto/contacto.component';

const appRoutes: Routes =[

  {path: '',component: HomeComponent},
      {path: 'productos',component: ProductosComponent},
    {path: 'contacto',component: ContactoComponent},

];


export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);

