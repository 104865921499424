import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-testigos',
  templateUrl: './testigos.component.html',
  styleUrls: ['./testigos.component.scss']
})
export class TestigosComponent implements OnInit {
stars = Array(5);
  constructor() { }

  ngOnInit() {
  }

}
