
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import {routing, appRoutingProviders} from './app.routing';

import { AppComponent } from './app.component';

import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { FormsModule } from '@angular/forms';
import { NavComponent } from './nav/nav.component';
import { SliderComponent } from './home/slider/slider.component';
import { HomeComponent } from './home/home.component';
import { CardsComponent } from './home/cards/cards.component';
import { ServiciosComponent } from './home/servicios/servicios.component';
import { CtaComponent } from './home/cta/cta.component';
import { FooterComponent } from './footer/footer.component';
import { ProductosComponent } from './productos/productos.component';
import { ContactoComponent } from './contacto/contacto.component';
import { TestigosComponent } from './footer/testigos/testigos.component';
import { MarcasComponent } from './productos/marcas/marcas.component';
import { TimelineComponent } from './productos/timeline/timeline.component';
import { SerComponent } from './home/ser/ser.component';
import { PreguntasComponent } from './preguntas/preguntas.component';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    SliderComponent,
    HomeComponent,
    CardsComponent,
    ServiciosComponent,
    CtaComponent,
    FooterComponent,
    ProductosComponent,
    ContactoComponent,
    TestigosComponent,
    MarcasComponent,
    TimelineComponent,
    SerComponent,
    PreguntasComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MDBBootstrapModule.forRoot(),
    FormsModule,
    routing,
  ],
  providers: [appRoutingProviders],
  bootstrap: [AppComponent]
})
export class AppModule { } 
